<template>
    <div cds-layout="grid gap:md cols:auto p:md align:shrink" class="header">
        <!-- Icon & Title -->
        <div cds-layout="horizontal gap:md align:vertical-center">
            <cds-icon shape="cross" size="25" />
            <div cds-layout="horizontal gap:xs">
                <span cds-text="title">Grabverwaltung</span>
            </div>
        </div>

        <div cds-layout="horizontal align:right">
            <cds-icon-button size="sm" action="flat" status="inverse" @click="togglePhoneDrawer">
                <cds-icon shape="bars" size="md" />
            </cds-icon-button>
        </div>
    </div>
</template>

<script>
import '@cds/core/icon/register.js';
import '@cds/core/button/register.js';
import { ClarityIcons, logoutIcon, barsIcon } from '@cds/core/icon';
import store from '@/store';
import ConfigurationParser from '@/js/ConfigurationParser.js';
import logout from '@/js/authentication/Logout';

export default {
    mounted() {
        ClarityIcons.addIcons(logoutIcon, barsIcon);
        const configurationParser = new ConfigurationParser();
        this.graveManagementName = configurationParser.getGraveManagementName();
    },
    data() {
        return {
            graveManagementName: "",
        }
    },
    methods: {
        logout: async function () {
            await logout();
        },
        togglePhoneDrawer: function () {
            // variable is in 'DrawerComponent' initialized
            store.state.phoneDrawerExpanded = !store.state.phoneDrawerExpanded;
        }
    }
}
</script>

<style scoped>
.header {
    background-color: var(--cds-alias-status-info);
    color: white;
    min-height: 52px;
}

.header * {
    color: white;
}
</style>