import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default async function resetPassword(email) {
    const auth = getAuth();

    // Send password reset email
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        throw new Error(error.message);
    }
}