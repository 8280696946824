<template>
    <cds-button :size="size || undefined" :action="action || undefined" :loading-state="loadingState"
        @click="$emit('onClick')">
        <slot />
    </cds-button>
</template>

<script>
export default {
    emits: ["onClick", "onLoadingDone"],
    props: {
        size: {
            type: String,
            default: null
        },
        action: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingState: "default"
        }
    },
    watch: {
        loading: async function (newValue) {
            if (newValue == true) {
                this.loadingState = "loading";
            } else {
                let state;

                if (this.error == true) {
                    state = "error";
                } else {
                    state = "success";
                }

                this.loadingState = state;

                setTimeout(() => {
                    this.loadingState = "default";
                    this.$emit("onLoadingDone");
                }, 1500);
            }
        }
    }
}
</script>

<style></style>