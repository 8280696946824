<template>
    <cds-input>
        <label></label>
        <input placeholder="Benutzername" v-model="this.username" @keyup.enter="this.login()"/>
        <cds-control-action action="suffix" readonly aria-label="user"> <!-- tabindex="-1" -->
            <cds-icon shape="user" solid />
        </cds-control-action>
        <cds-control-message v-if="this.error" status="error" />
    </cds-input>

    <cds-password>
        <label></label>
        <input type="password" placeholder="Passwort" v-model="this.password" @keyup.enter="this.login()"/>
        <cds-control-message v-if="this.error" status="error">Benutzername oder Passwort ist
            falsch!</cds-control-message>
    </cds-password>

    <LoginButton :loading="this.initFirebase" :error="this.error" @on-click="login()">
        <template v-slot:default>Anmelden</template>

        <template v-slot:under>
            <cds-button-inline @click="this.$emit('changeComponent', 'ResetPassword')">Passwort
                vergessen</cds-button-inline>
        </template>
    </LoginButton>
</template>

<script>
import getUserFromBrowser from '@/js/authentication/GetUserFromBrowser';
import '@cds/core/input/register.js';
import '@cds/core/password/register.js';
import '@cds/core/icon/register.js';
import '@cds/core/button-inline/register.js';
import { ClarityIcons, userIcon } from '@cds/core/icon';
import loginFirebase from '@/js/authentication/Login';
import LoginButton from './LoginButton.vue';

export default {
    emits: ["onLogin", "changeComponent"],
    components: {
        LoginButton,
    },
    data() {
        return {
            username: "",
            password: "",
            error: false,
            initFirebase: false,
        }
    },
    async mounted() {
        const user = await getUserFromBrowser();

        if (user) await this.login(user);

        ClarityIcons.addIcons(userIcon);
    },
    methods: {
        login: async function (user = null) {
            if (!user) {
                const username = this.username.trim();
                const password = this.password.trim();

                this.initFirebase = true;
                user = await loginFirebase(username, password);
                if (user == null) {
                    return this.showError();
                }
                this.initFirebase = false;
            }

            this.$emit("onLogin", user);
        },
        showError: function () {
            this.password = "";
            this.error = true;
            this.initFirebase = false;
        },
    }
}
</script>

<style scoped></style>