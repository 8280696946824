<template>
    <span cds-text="body">
        Bitte geben Sie Ihre E-Mail-Adresse zum Zurücksetzen Ihres Passworts ein.<br>
        Wir schicken Ihnen innerhalb von 5 Minuten einen Link zum Ändern des Passworts.
    </span>

    <cds-input>
        <label></label>
        <input type="email" placeholder="E-Mail-Adresse" v-model="this.email" @keyup.enter="this.send" />
        <cds-control-action action="suffix" readonly aria-label="email">
            <cds-icon shape="envelope" solid />
        </cds-control-action>
        <cds-control-message v-if="this.error" status="error">{{ this.error }}</cds-control-message>
    </cds-input>

    <LoginButton :loading="this.buttonLoading" :error="this.buttonError" @on-click="send">
        <template v-slot:default>Passwort zurücksetzen</template>

        <template v-slot:under>
            <cds-button-inline @click="this.$emit('changeComponent', 'UsernamePasswordLogin')">Zurück zum
                Login</cds-button-inline>
        </template>
    </LoginButton>
</template>

<script>
import LoginButton from './LoginButton.vue';
import '@cds/core/button-inline/register.js';
import '@cds/core/input/register.js';
import { ClarityIcons, envelopeIcon } from '@cds/core/icon';
import resetPassword from '@/js/authentication/ResetPassword';
import { nextTick } from 'vue';

export default {
    emits: ["onLogin", "changeComponent"],
    components: {
        LoginButton,
    },
    data() {
        return {
            email: null,
            error: null,
            buttonLoading: false,
            buttonError: false,
        }
    },
    mounted() {
        ClarityIcons.addIcons(envelopeIcon);
    },
    methods: {
        send: async function () {
            // Clear errors
            this.error = null;
            this.buttonError = false;

            // Start loading
            this.buttonLoading = true;

            // Check if real email was input
            if (this.isValidEmail(this.email)) {
                try {
                    await resetPassword(this.email);
                } catch (error) {
                    this.error = "Fehler beim Senden der E-Mail"
                    this.buttonError = true;
                }
            } else {
                await nextTick();
                this.error = "Keine richtige E-Mail-Adresse";
                this.buttonError = true;
            }

            // Stop loading
            this.buttonLoading = false;
        },
        isValidEmail: function (email) {
            // Define the regex for a valid email address
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Test the email against the regex
            return emailRegex.test(email);
        }
    },
}
</script>

<style scoped></style>