<template>
    <span cds-text="section medium" class="loading-text">
        Die Grabverwaltung wird vorbereitet ...
    </span>
</template>

<script>
export default {}
</script>

<style scoped>
.loading-text {
    color: #8A8A8A;
}
</style>