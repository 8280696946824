<template>
    <div cds-layout="vertical align:center gap:md m-t:xxxl">
        <div cds-layout="horizontal align:stretch p-l:xxxl p-r:xxxl">
            <LoadingButtonComponent class="login-button" :loading="this.loading" :error="this.error"
                @on-click="this.$emit('onClick')">
                <slot />
            </LoadingButtonComponent>
        </div>

        <slot name="under" />
    </div>
</template>

<script>
import '@cds/core/button/register.js';
import LoadingButtonComponent from '../input/LoadingButtonComponent.vue';

export default {
    emits: ["onClick"],
    props: {
        loading: Boolean,
        error: Boolean,
    },
    components: {
        LoadingButtonComponent,
    },
}
</script>

<style scoped>
.login-button {
    --background: #E9E9E9;
    box-shadow: 0px 2px 2px 0px #00000040;
    --color: black;
    --border-radius: 0px
}
</style>